import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useLocation} from 'react-router-dom';
import {
    Container,
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Paper,
    CircularProgress, List, ListItem, ListItemIcon, ListItemText, Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Graph from "./Graph";
import APIService from './APIService';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {useStyles} from './styles';
import {styled} from '@mui/material/styles';

const CombinedResults = () => {
        const classes = useStyles();
        const {state} = useLocation();
        const [topPapers, setTopPapers] = useState([]);
        const [restPapers, setRestPapers] = useState([]);
        const [allPapers, setAllPapers] = useState([]);
        const [graphDescription, setGraphDescription] = useState("");
        const [error, setError] = useState(null);
        const [papersSummary, setPapersSummary] = useState("");
        const [isSummaryLoading, setIsSummaryLoading] = useState(false);
        const [isSummaryGenerated, setIsSummaryGenerated] = useState(false);
        const [highlightedPrompt, setHighlightedPrompt] = useState('');
        const HighlightedText = styled('span')(({theme}) => ({
            backgroundColor: theme.palette.warning.light,
            padding: '0 4px',
            borderRadius: '2px',
        }));
        const generateGraphDescription = useCallback((nodes, links) => {
            if (nodes.length === 0 || links.length === 0) {
                return "No graph data available.";
            }

            const nodeCount = new Set(nodes.map(node => node.id)).size;
            const uniqueLinks = new Set(links.map(link => `${link.source}-${link.target}-${link.label}`));
            const linkCount = uniqueLinks.size;
            const nodeGroups = [...new Set(nodes.map(node => node.group))];
            const relationshipTypes = [...new Set(links.map(link => link.label))];

            return `This graph represents a solution with ${nodeCount} unique components across ${nodeGroups.length} categories: ${nodeGroups.join(", ")}. 
             These components are connected through ${linkCount} unique relationships, including ${relationshipTypes.slice(0, 5).join(", ")}${relationshipTypes.length > 5 ? ", and others" : ""}. 
             This structure suggests a ${nodeCount > 10 ? "complex" : "simple"} system with ${linkCount > nodeCount ? "high" : "moderate"} interconnectivity. 
             Key components include ${[...new Set(nodes.slice(0, 3).map(node => node.id))].join(", ")}, which play central roles in the system.`;
        }, []);

        const processGraphData = (graphData) => {
            console.log("Raw graph data:", graphData);

            const nodeMap = new Map();
            const uniqueLinks = new Map();

            Object.entries(graphData).forEach(([key, item]) => {

                if (!item || typeof item !== 'object') {
                    console.warn('Invalid item in graph data:', item);
                    return; // Skip this item
                }

                const {Ontology_Triple} = item;
                if (!Ontology_Triple || typeof Ontology_Triple !== 'string') {
                    console.warn('Invalid Ontology_Triple:', Ontology_Triple);
                    return; // Skip this item
                }

                const [source, label, target] = Ontology_Triple.split(' ');
                console.log(`Extracted: source=${source}, label=${label}, target=${target}`);

                if (source) {
                    if (!nodeMap.has(source)) {
                        nodeMap.set(source, {id: source, group: getNodeGroup(source)});
                    }
                }

                if (target) {
                    if (!nodeMap.has(target)) {
                        nodeMap.set(target, {id: target, group: getNodeGroup(target)});
                    }
                }

                if (source && target && label) {
                    const linkKey = `${source}-${target}-${label}`;
                    if (!uniqueLinks.has(linkKey)) {
                        uniqueLinks.set(linkKey, {source, target, label});
                    }
                }
            });

            const result = {
                nodes: Array.from(nodeMap.values()),
                links: Array.from(uniqueLinks.values())
            };
            console.log("Processed graph data:", result);
            return result;
        };

        const {nodes, links} = useMemo(() => {
            console.log("State in useMemo:", state);
            if (!state || !state.graph || typeof state.graph !== 'object') {
                console.error("Invalid graph data:", state?.graph);
                setError("Invalid graph data structure");
                return {nodes: [], links: []};
            }

            try {
                const processedData = processGraphData(state.graph);
                return processedData;
            } catch (err) {
                setError("Error processing graph data: " + err.message);
                return {nodes: [], links: []};
            }
        }, [state]);

        function getNodeGroup(nodeName) {
            if (!nodeName || typeof nodeName !== 'string') {
                return 'other'; // Default group for undefined or non-string nodes
            }
            const lowerName = nodeName.toLowerCase();
            if (lowerName.includes('data')) return 'data';
            if (lowerName.includes('system')) return 'system';
            if (lowerName.includes('business')) return 'business';
            if (lowerName.includes('technology')) return 'technology';
            return 'other';
        }

        const generatePapersSummary = useCallback(async (papers, graphData) => {
            setIsSummaryLoading(true);
            try {
                const response = await APIService.SummarizePapers(papers, 300, graphData);
                console.log('Summary response:', response);
                return response.summary;
            } catch (error) {
                console.error('Error generating summary:', error);
                return `Unable to generate summary at this time. Error: ${error.message}`;
            } finally {
                setIsSummaryLoading(false);
            }
        }, []);

        const memoizedGraphData = useMemo(() => ({nodes, links}), [nodes, links]);

        const handleGenerateSummary = useCallback(async () => {
            if (isSummaryGenerated) return;
            setIsSummaryLoading(true);
            try {
                if (allPapers.length === 0) {
                    throw new Error("No papers available for summarization");
                }

                // Limit the content of each paper
                const limitedPapers = allPapers.map(paper => ({
                    ...paper,
                    content: paper.content.substring(0, 1000) // Limit to 1000 characters per paper
                }));

                console.log("Sending papers to API:", limitedPapers);
                console.log("Sending graph data to API:", memoizedGraphData);

                const summary = await generatePapersSummary(limitedPapers, memoizedGraphData);
                setPapersSummary(summary || "No summary available.");
                setIsSummaryGenerated(true);
            } catch (error) {
                console.error('Error generating summary:', error);
                setPapersSummary("Error generating summary: " + error.message);
            } finally {
                setIsSummaryLoading(false);
            }
        }, [generatePapersSummary, allPapers, memoizedGraphData, isSummaryGenerated]);

        useEffect(() => {
            if (state && state.prompt) {
                const importantTokens = ['risk', 'detection', 'prevention', 'healthcare', 'system', 'data', 'technology', 'business'];
                const words = state.prompt.split(' ');
                const highlightedWords = words.map(word =>
                    importantTokens.some(token => word.toLowerCase().includes(token))
                        ? `<highlight>${word}</highlight>`
                        : word
                );
                setHighlightedPrompt(highlightedWords.join(' '));
            }
        }, [state]);

        useEffect(() => {
            if (state && state.search && state.search.Topic && state.search['URL/PDF']) {
                const papers = Object.entries(state.search.Topic).map(([id, paper]) => ({
                    id: parseInt(id),
                    documentId: state.search['URL/PDF'][parseInt(id) - 1] || '',
                    content: paper.content,
                    score: paper.score
                }));

                papers.sort((a, b) => b.score - a.score);
                setAllPapers(papers);
                setTopPapers(papers.slice(0, 3));
                setRestPapers(papers.slice(3));
            } else {
                console.error("Invalid paper data:", state?.search);
                setError("Invalid paper data structure");
            }

            setGraphDescription(generateGraphDescription(nodes, links));
        }, [state, generateGraphDescription, nodes, links]);


        const formatDocumentId = (url) => {
            const parts = url.split('/');
            if (parts[0] === 'document') {
                const [id, version] = parts[1].split('v');
                return `arXiv:${id}${version ? ` (v${version})` : ''}`;
            }
            return url;
        };

        const getArXivUrl = (documentId) => {
            const [id] = documentId.split('/')[1].split('v');
            return `https://arxiv.org/abs/${id}`;
        };

        const parseSummary = (summary) => {
            const parts = summary.split(/(?=\d+\.\s*\*\*)/);
            return parts.map(part => {
                const [title, content] = part.split(':**');
                if (content) {
                    const bulletPoints = content.match(/\s*-\s*(.*?)(?=\s*-|\s*$)/g) || [];
                    return {
                        title: title.trim(),
                        bulletPoints: bulletPoints.map(point => point.replace(/^\s*-\s*/, '').trim())
                    };
                }
                return {content: part.trim()};
            });
        };

        return (
            <Container maxWidth="xl">
                {state && state.prompt && (
                    <Paper elevation={3} style={{padding: '15px', marginBottom: '20px', backgroundColor: '#f0f0f0'}}>
                        <Typography variant="h6" gutterBottom>Original Prompt:</Typography>
                        <Typography variant="body1" component="div" dangerouslySetInnerHTML={{
                            __html: highlightedPrompt.replace(/<highlight>/g, '<span style="background-color: #fff176; padding: 0 4px; border-radius: 2px;">').replace(/<\/highlight>/g, '</span>')
                        }}/>
                    </Paper>
                )}
                <Typography variant="h4" gutterBottom>Solution Design Recommendations</Typography>

                {error && (
                    <Paper elevation={3} style={{padding: '15px', marginBottom: '20px', backgroundColor: '#ffcccb'}}>
                        <Typography variant="h6" color="error">Error: {error}</Typography>
                    </Paper>
                )}

                <Paper elevation={3} style={{padding: '15px', marginBottom: '20px'}}>
                    <Typography variant="h5" gutterBottom>Graph View</Typography>
                    <Typography variant="body1" paragraph>{graphDescription}</Typography>
                    <div style={{height: '70vh', width: '100%'}}>
                        {nodes.length > 0 && links.length > 0 ? (
                            <Graph nodes={nodes} links={links} initialZoom={0.2}/>
                        ) : (
                            <Typography variant="body1">No graph data available to visualize.</Typography>
                        )}
                    </div>
                </Paper>

                <Paper elevation={3} style={{padding: '15px', marginBottom: '20px'}}>
                    <Typography variant="h5" gutterBottom>Solution Summary</Typography>
                    {!isSummaryGenerated ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleGenerateSummary}
                            disabled={isSummaryLoading}
                        >
                            {isSummaryLoading ? 'Generating...' : 'Generate'}
                        </Button>
                    ) : isSummaryLoading ? (
                        <CircularProgress/>
                    ) : papersSummary ? (
                        <>
                            {parseSummary(papersSummary).map((section, index) => (
                                <div key={index} className={classes.summarySection}>
                                    {section.title ? (
                                        <>
                                            <Typography variant="h6"
                                                        className={classes.sectionTitle}>{section.title}</Typography>
                                            <List className={classes.bulletList}>
                                                {section.bulletPoints.map((point, pointIndex) => (
                                                    <ListItem key={pointIndex} className={classes.bulletItem}>
                                                        <ListItemIcon className={classes.bulletIcon}>
                                                            <FiberManualRecordIcon style={{fontSize: 8}}/>
                                                        </ListItemIcon>
                                                        <ListItemText primary={point} className={classes.bulletText}/>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </>
                                    ) : (
                                        <Typography variant="body1"
                                                    className={classes.paragraph}>{section.content}</Typography>
                                    )}
                                    {index < parseSummary(papersSummary).length - 1 &&
                                        <Divider style={{margin: '10px 0'}}/>}
                                </div>
                            ))}
                        </>
                    ) : (
                        <Typography variant="body1">No summary available.</Typography>
                    )}
                </Paper>

                <Typography variant="h5" gutterBottom>Top Papers</Typography>
                <Grid container spacing={3}>
                    {topPapers.map((paper) => (
                        <Grid item xs={12} md={4} key={paper.id}>
                            <Paper elevation={3} style={{padding: '15px', height: '100%'}}>
                                <Typography variant="h6">
                                    <a href={getArXivUrl(paper.documentId)} target="_blank" rel="noreferrer">
                                        {formatDocumentId(paper.documentId)}
                                    </a>
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Score: {paper.score.toFixed(4)}
                                </Typography>
                                <Typography variant="body1">
                                    {paper.content.substring(0, 200)}...
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>

                {restPapers.length > 0 && (
                    <Accordion style={{marginTop: '20px'}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>View More Papers ({restPapers.length})</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3}>
                                {restPapers.map((paper) => (
                                    <Grid item xs={12} md={4} key={paper.id}>
                                        <Paper elevation={3} style={{padding: '15px', height: '100%'}}>
                                            <Typography variant="h6">
                                                <a href={getArXivUrl(paper.documentId)} target="_blank" rel="noreferrer">
                                                    {formatDocumentId(paper.documentId)}
                                                </a>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                Score: {paper.score.toFixed(4)}
                                            </Typography>
                                            <Typography variant="body1">
                                                {paper.content.substring(0, 200)}...
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}

                <Button variant="contained" color="primary" href="/app" style={{marginTop: '20px'}}>
                    Specify Your Problem Definition
                </Button>
            </Container>
        );
    }
;

export default CombinedResults;