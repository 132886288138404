import React from "react";
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import {styled, ThemeProvider, createTheme} from '@mui/material/styles';
import {AppBar, Toolbar, Typography, Button} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import Home from './Home';
import {ApolloProvider, ApolloClient, InMemoryCache, HttpLink} from "@apollo/client";
import CombinedResults from "./CombinedResults";

const createApolloClient = () => {
  const graphqlUrl = process.env.REACT_APP_GRAPH_QL;
  console.log("Creating Apollo client with GraphQL URL:", graphqlUrl);
  const link = new HttpLink({
    uri: graphqlUrl.endsWith('/') ? graphqlUrl.slice(0, -1) : graphqlUrl
  });
  return new ApolloClient({
    link,
    cache: new InMemoryCache()
  });
}

const theme = createTheme();

// Define styled components instead of useStyles
const Root = styled('div')(({theme}) => ({
    flexGrow: 1,
}));

const Title = styled(Typography)(({theme}) => ({
    flexGrow: 1,
}));


function Routing() {
    console.log("GraphQL URL:", process.env.REACT_APP_GRAPH_QL);
    console.log("Backend URL:", process.env.REACT_APP_BACKEND);

    return (
        <ApolloProvider client={createApolloClient()}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <BrowserRouter>
                    <Root>
                        <AppBar position="static" style={{background: '#595959'}}>
                            <Toolbar>
                                <Title variant="h6">
                                    Solution Finder
                                </Title>
                                <Button color="inherit" href="/">Home</Button>
                                <Button color="inherit" href="/app">App</Button>
                            </Toolbar>
                        </AppBar>
                    </Root>
                    <Routes>
                        <Route exact path="/" element={<Home/>}/>
                        <Route exact path="/app" element={<App/>}/>
                        <Route exact path="/results" element={<CombinedResults/>}/>
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </ApolloProvider>
    );
}

export default Routing;