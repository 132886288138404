import "./App.css";
import React from 'react';
import Container from "@material-ui/core/Container";
import Form from './Form';


function App() {
    return (
         <Container>

            <Form/>

        </Container>
    );
}

export default App;