export default class APIService {
    static BASE_URL = process.env.REACT_APP_BACKEND;

    static async fetchWithErrorHandling(endpoint, method, body) {
        try {
            console.log(`Sending request to ${this.BASE_URL}${endpoint}`, {method, body});

            const response = await fetch(`${this.BASE_URL}${endpoint}`, {
                method,
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            const responseText = await response.text();
            let data;

            try {
                data = JSON.parse(responseText);
            } catch (e) {
                console.error("Failed to parse response as JSON:", responseText);
                throw new Error(`Invalid JSON response from server: ${responseText}`);
            }

            if (!response.ok) {
                console.error(`Server responded with status ${response.status}:`, data);
                throw new Error(`HTTP error! status: ${response.status}, message: ${JSON.stringify(data)}`);
            }

            console.log(`Received response from ${endpoint}:`, data);

            if (data.error) {
                console.error("Error from server:", data.error);
                if (data.traceback) {
                    console.error("Traceback:", data.traceback);
                }
                throw new Error(data.error);
            }

            return data;
        } catch (error) {
            console.error(`Error in ${endpoint}:`, error);
            throw error;
        }
    }

    static async SearchQuery(text_input) {
        if (!text_input || typeof text_input !== 'string' || text_input.trim() === '') {
            throw new Error("Please provide a non-empty search query.");
        }
        const data = await this.fetchWithErrorHandling('/query/', 'POST', {text_input});
        console.log("SearchQuery response:", data);
        return data;
    }


    static async GetGraphDetails(data) {
        if (!data || !data.text_input || typeof data.text_input !== 'string' || data.text_input.trim() === '') {
            throw new Error("Please provide a non-empty input for graph details.");
        }
        const response = await this.fetchWithErrorHandling('/result/', 'POST', data);
        console.log("GetGraphDetails response:", response);
        return response;
    }

    static async SummarizePapers(papers, maxWords = 500, graphData) {
        console.log("SummarizePapers called with:", {papers: papers.length, maxWords, graphData});

        // Limit the total content length
        let totalContent = papers.map(p => p.content).join(' ');
        if (totalContent.length > 200000) { // Adjust this number as needed
            totalContent = totalContent.substring(0, 200000) + '...';
        }

        const response = await this.fetchWithErrorHandling('/summarize/', 'POST', {
            papers: totalContent,
            max_words: maxWords,
            graph_data: graphData
        });
        return response;
    }

}