import React, {useRef, useEffect, useState} from 'react';
import * as d3 from 'd3';

const Graph = ({nodes, links, initialZoom = 0.2}) => {
    const svgRef = useRef(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!nodes || nodes.length === 0 || !links || links.length === 0) {
            console.error("Invalid nodes or links data:", {nodes, links});
            setError("Invalid graph data");
            return;
        }

        try {
            const svg = d3.select(svgRef.current);
            const width = svg.node().getBoundingClientRect().width;
            const height = svg.node().getBoundingClientRect().height;

            svg.selectAll("*").remove();

            // Zoom functionality
            const zoom = d3.zoom()
                .scaleExtent([0.1, 4])
                .on("zoom", (event) => {
                    g.attr('transform', event.transform);
                });

            if (isNaN(width) || isNaN(height) || width === 0 || height === 0) {
                console.error("Invalid SVG dimensions:", {width, height});
                setError("Invalid SVG dimensions");
                return;
            }

            const color = d3.scaleOrdinal()
                .domain(['data', 'system', 'business', 'technology', 'other'])
                .range(['#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#999999']);

            const simulation = d3.forceSimulation(nodes)
                .force("link", d3.forceLink(links).id(d => d.id).distance(150))
                .force("charge", d3.forceManyBody().strength(-500))
                .force("center", d3.forceCenter(width / 2, height / 2))
                .force("collision", d3.forceCollide().radius(40));

            svg.call(zoom);
            const g = svg.append("g");

            const link = g.append("g")
                .selectAll("line")
                .data(links)
                .join("line")
                .attr("stroke", "#999")
                .attr("stroke-opacity", 0.6)
                .attr("stroke-width", 1);

            const linkLabel = g.append("g")
                .selectAll("text")
                .data(links)
                .join("text")
                .attr("font-size", "8px")
                .attr("fill", "#555")
                .text(d => d.label);

            const node = g.append("g")
                .selectAll("circle")
                .data(nodes)
                .join("circle")
                .attr("r", 25)
                .attr("fill", d => color(d.group))
                .call(drag(simulation));

            const nodeLabel = g.append("g")
                .selectAll("text")
                .data(nodes)
                .join("text")
                .text(d => d.id)
                .attr("font-size", "8px")
                .attr("dx", 12)
                .attr("dy", 4);

            // Add legend
            const legend = svg.append("g")
                .attr("font-family", "sans-serif")
                .attr("font-size", 10)
                .attr("text-anchor", "start")
                .selectAll("g")
                .data(color.domain())
                .join("g")
                .attr("transform", (d, i) => `translate(10,${i * 20 + 10})`);

            legend.append("rect")
                .attr("x", width - 100)
                .attr("width", 19)
                .attr("height", 19)
                .attr("fill", color);

            legend.append("text")
                .attr("x", width - 75)
                .attr("y", 9.5)
                .attr("dy", "0.32em")
                .text(d => d);

            simulation.on("tick", () => {
                link
                    .attr("x1", d => isFinite(d.source.x) ? d.source.x : 0)
                    .attr("y1", d => isFinite(d.source.y) ? d.source.y : 0)
                    .attr("x2", d => isFinite(d.target.x) ? d.target.x : 0)
                    .attr("y2", d => isFinite(d.target.y) ? d.target.y : 0);

                linkLabel
                    .attr("x", d => (d.source.x + d.target.x) / 2)
                    .attr("y", d => (d.source.y + d.target.y) / 2);

                node
                    .attr("cx", d => isFinite(d.x) ? d.x : 0)
                    .attr("cy", d => isFinite(d.y) ? d.y : 0);

                nodeLabel
                    .attr("x", d => isFinite(d.x) ? d.x : 0)
                    .attr("y", d => isFinite(d.y) ? d.y : 0);
            });

            // Reintroduce the drag function
            function drag(simulation) {
                function dragstarted(event) {
                    if (!event.active) simulation.alphaTarget(0.3).restart();
                    event.subject.fx = event.subject.x;
                    event.subject.fy = event.subject.y;
                }

                function dragged(event) {
                    event.subject.fx = event.x;
                    event.subject.fy = event.y;
                }

                function dragended(event) {
                    if (!event.active) simulation.alphaTarget(0);
                    event.subject.fx = null;
                    event.subject.fy = null;
                }

                return d3.drag()
                    .on("start", dragstarted)
                    .on("drag", dragged)
                    .on("end", dragended);
            }


            /// Set initial zoom
            svg.call(zoom.transform, d3.zoomIdentity
                .translate(width / 2, height / 2)
                .scale(initialZoom)
                .translate(-width / 2, -height / 2));


        } catch (err) {
            console.error("Error rendering graph:", err);
            setError("Error rendering graph: " + err.message);
        }
    }, [nodes, links, initialZoom]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    return <svg ref={svgRef} style={{width: '100%', height: '100%'}}/>;
}

export default Graph;